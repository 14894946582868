<template>
  <div class="page">
    Error 404: page not found
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>
