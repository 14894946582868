var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.guilds)?_c('div',{staticClass:"guild-selector"},[_vm._l((_vm.guilds),function(ref){
var guild = ref.guild;
return _c('div',{key:guild.id,staticClass:"server-icon-wrapper"},[_c('server-icon',{attrs:{"name":guild.name,"icon":guild.iconURL,"acronym":guild.nameAcronym,"guild-id":guild.id}})],1)}),(false)?[_vm._l((_vm.guilds),function(ref){
var guild = ref.guild;
return _c('div',{key:guild.id,staticClass:"server-icon-wrapper"},[_c('server-icon',{attrs:{"name":guild.name,"icon":guild.iconURL,"acronym":guild.nameAcronym,"guild-id":guild.id}})],1)}),_vm._l((_vm.guilds),function(ref){
var guild = ref.guild;
return _c('div',{key:guild.id,staticClass:"server-icon-wrapper"},[_c('server-icon',{attrs:{"name":guild.name,"icon":guild.iconURL,"acronym":guild.nameAcronym,"guild-id":guild.id}})],1)}),_vm._l((_vm.guilds),function(ref){
var guild = ref.guild;
return _c('div',{key:guild.id,staticClass:"server-icon-wrapper"},[_c('server-icon',{attrs:{"name":guild.name,"icon":guild.iconURL,"acronym":guild.nameAcronym,"guild-id":guild.id}})],1)}),_vm._l((_vm.guilds),function(ref){
var guild = ref.guild;
return _c('div',{key:guild.id,staticClass:"server-icon-wrapper"},[_c('server-icon',{attrs:{"name":guild.name,"icon":guild.iconURL,"acronym":guild.nameAcronym,"guild-id":guild.id}})],1)}),_vm._l((_vm.guilds),function(ref){
var guild = ref.guild;
return _c('div',{key:guild.id,staticClass:"server-icon-wrapper"},[_c('server-icon',{attrs:{"name":guild.name,"icon":guild.iconURL,"acronym":guild.nameAcronym,"guild-id":guild.id}})],1)}),_vm._l((_vm.guilds),function(ref){
var guild = ref.guild;
return _c('div',{key:guild.id,staticClass:"server-icon-wrapper"},[_c('server-icon',{attrs:{"name":guild.name,"icon":guild.iconURL,"acronym":guild.nameAcronym,"guild-id":guild.id}})],1)}),_vm._l((_vm.guilds),function(ref){
var guild = ref.guild;
return _c('div',{key:guild.id,staticClass:"server-icon-wrapper"},[_c('server-icon',{attrs:{"name":guild.name,"icon":guild.iconURL,"acronym":guild.nameAcronym,"guild-id":guild.id}})],1)}),_vm._l((_vm.guilds),function(ref){
var guild = ref.guild;
return _c('div',{key:guild.id,staticClass:"server-icon-wrapper"},[_c('server-icon',{attrs:{"name":guild.name,"icon":guild.iconURL,"acronym":guild.nameAcronym,"guild-id":guild.id}})],1)})]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }